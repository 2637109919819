import React, { useEffect, useState } from "react";
import './index.scss'
import { BsCheck } from 'react-icons/bs'

interface props {
    description: string
    checked: boolean
    change: (e, stats) => void
    value?: any
    preventUncheck?: boolean
    disabled?: boolean
}




export function CheckBoxCustom({ description, checked, change, value, preventUncheck, disabled }: props) {
    const [isChecked, setIsChecked] = useState(Boolean)
    useEffect(() => {

        setIsChecked(checked)
    }, [checked])
    function handleChecked(data) {
        //isChecked ? change(null,false) : change(data,true)
        isChecked ? change(data, false) : change(data, true)
        setIsChecked(!isChecked)
    }
    function handleCheck(data) {
        isChecked ? () => { } : change(data, true)
    }

    return (
        <div
            className="customCheckContainer"

            onClick={disabled ? () => { } : preventUncheck ? () => handleCheck(value) : () => handleChecked(value)}>
            <div style={{ minWidth: 25, }} className={` checkArea  ${disabled ? 'disabledArea' : ''}`}>
                {isChecked && <BsCheck size={32} className="CheckBIcon" />}
            </div>
            <p className="checkDescription">{description}</p>
        </div>
    )

}
