/* eslint-disable react/jsx-curly-spacing */
import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import Constants from '../../../../constants'
import { useForm } from 'react-hook-form'
import { FeedbackError } from '../../../../components/feedback/error'
import CurrencyInput from 'react-currency-input'
import Utils from '../../../../utils'
import './index.scss'
import { Input } from 'components/input'
import { Button } from 'components/button'
import { UserContext } from 'contexts/user'
import { Select } from 'components/select'
import ProductService from 'services/ProductService'
import ProfileService from 'services/v3/profile.service'
import { Link } from 'react-router-dom'

import { BiLeftArrowAlt } from 'react-icons/bi'
import { MdSave } from 'react-icons/md'
import ButtonV3 from 'components/Buttonv3'
import IconReact from 'components/icons'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import axios from 'axios'
import AuthService from 'services/v3/auth.service'
import * as Auth from 'services/AuthService'
import { CheckBoxCustom } from 'components/checkBoxCustom'
const RepresentativeForm: FC<any> = ({ saveCallback, representative }: any) => {

    const { can, user } = useContext(UserContext)
    const { register, handleSubmit, errors } = useForm()
    const [group, setGroup] = useState('')
    const [groups, setGroups] = useState([])
    const [isMaster, setIsMaster] = useState(false)
    const [isSupervisor, setIsSupervisor] = useState(false)
    const [selectedSupervisor, setSelectedSupervisor] = useState('')
    const [isSalesmanPlus, setIsSalesmanPlus] = useState(false)

    const [minimumBilling, setMinimumBilling] = useState('0')
    const [profiles, setProfiles] = useState<any>(null)
    const MySwal = withReactContent(Swal)

    useEffect(() => {
        if (user) {
            ProductService.groups(user.providerId).then((result) => setGroups(result.groups))
            loadProfiles()
        }
    }, [user])

    const loadProfiles = useCallback(() => {
        ProfileService.load()
            .then(data => setProfiles(data))
    }, [])

    useEffect(() => {
        if (representative) {
            setIsMaster(representative.isMaster)
            setIsSupervisor(representative.isSupervisor)
            setIsSalesmanPlus(representative.isSalesmanPlus)
            setMinimumBilling(
                representative.minimumBilling
                    ? Utils.formatPrice(parseFloat(representative.minimumBilling))
                    : '0'
            )
            setGroup(representative.group_id)
        }
    }, [representative])

    const save = (data: any) => {
        const representativeToSave: any = {
            nome: data.name,
            email: data.emailUser,
            telefone: data.phone ? data.phone.replace(/\D/g, '') : '',
            // cpf: data.cpf,
            faturamento: minimumBilling.replaceAll('.', '').replaceAll(',', '.'),
            master: isMaster,
            senha: data.passwordUser,
            supervisor: isSupervisor,
            supervisor_select: selectedSupervisor,
            vendedor_plus: isSalesmanPlus,
            pfl_id: data.profile_id
        }

        if (group) {
            representativeToSave.group_id = group
        }

        saveCallback(representativeToSave)
    }

    const updateShowSupervisor = (isMaster, isSupervisor) => {
        if (isMaster || isSupervisor) {
            setSelectedSupervisor('')
        }
    }

    const updateIsMaster = () => {
        const newIsMasterValue = !isMaster
        setIsMaster(newIsMasterValue)
        updateShowSupervisor(newIsMasterValue, isSupervisor)
    }

    function handleRemoveUser() {
        MySwal.fire({
            title: 'Você quer excluir este usuário?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, excluir',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                confirmRemove()

            }
        })
    }
    async function confirmRemove() {
        try {
            AuthService.removeRepresentative(user.id).then(res => {
                Auth.default.signOut()
                window.location.href = Constants.ROUTE_SIGNIN
            })
        } catch {
            alert('erro ao excluir usuário')
        }
        // console.log('confirm remove')
        // this.setState({ removeModalVisible: false })
        // try {
        //     this.setState({ loading: true });

        //     const { status } = await AuthAPI.remove(this.props.usuario.usu_id);
        //     if (status && status !== 200) {
        //         throw new Error(`status: ${status}`);
        //     }
        //     await AsyncStorage.clear();

        //     this.props.navigation.dispatch(
        //         StackActions.reset({
        //             index: 0,
        //             actions: [NavigationActions.navigate({ routeName: 'Login' })]
        //         })
        //     );
        //     this.setState({ succesRemove: true })
        //     // Toast.show({
        //     //     duration: 2000,
        //     //     text: 'Usuário removido com sucesso!',
        //     //     buttonText: 'ok'
        //     // });
        // } catch (e) {
        //     console.log('Error', e);
        //     Toast.show({
        //         duration: 2000,
        //         text: 'Houve um problema! Tente novamente em alguns instantes!',
        //         buttonText: 'ok'
        //     });
        // } finally {
        //     this.setState({ loading: false });
        // }

    }
    return (
        <>
            <form onSubmit={handleSubmit(save)}>
                {representative &&
                    <>

                        <div style={{ 'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'flex-end', marginBottom: 15 }}>

                            {can('UPDATE', 'PROVIDER') &&
                                <div style={{ 'marginRight': 20 }}>

                                    <Link to={`${Constants.ROUTE_PROVIDER_EDIT}?r=1`} >
                                        <ButtonV3
                                            title='Voltar'
                                            Icon={<BiLeftArrowAlt className='iconButtonV3'></BiLeftArrowAlt>}
                                        //onClick={() => { }}
                                        />
                                    </Link>
                                </div>
                            }
                            <div style={{ display: 'flex', gap: 15, }}>
                                <ButtonV3
                                    style={{ borderColor: 'red' }}
                                    type="button"
                                    onClick={() => handleRemoveUser()}
                                    title='Excluir sua conta'
                                    Icon={<IconReact type='trash' color={'red'} className='iconButtonV3'></IconReact>}
                                //onClick={() => { }}
                                />
                                <ButtonV3
                                    type="submit"
                                    title='Salvar'
                                    Icon={<MdSave className='iconButtonV3'></MdSave>}
                                //onClick={() => { }}
                                />

                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-12 col-md-6">
                                <Input id="name"
                                    name="name"
                                    placeholder="Nome"
                                    description="Nome"
                                    register={register({ required: true })}
                                    defaultValue={representative ? representative.name : ''}
                                />
                                {errors.name && <FeedbackError message={Constants.MSG_REQUIRED_FIELD} />}
                            </div>
                            <div className="form-group col-12 col-md-6">
                                <Input id="emailUser"
                                    name="emailUser"
                                    placeholder="E-mail"
                                    description="E-mail"
                                    autoComplete="off"
                                    register={register({ required: true })}
                                    defaultValue={representative ? representative.email : ''}
                                />
                                {errors.emailUser && <FeedbackError message={Constants.MSG_REQUIRED_FIELD} />}
                            </div>
                            {/* <div className="form-group col-12 col-md-6">
                                <Input id="cpf"
                                    name="cpf"
                                    placeholder="CPF"
                                    description="CPF"
                                    mask="999.999.999-99"
                                    register={register({ required: true })}
                                    defaultValue={representative ? representative.cpf : ''}
                                />
                                {errors.cpf && <FeedbackError message={Constants.MSG_REQUIRED_FIELD} />}
                            </div> */}
                            <div className="form-group col-12 col-md-6">
                                <Input id="passwordUser"
                                    type="password"
                                    name="passwordUser"
                                    placeholder="Senha"
                                    description="Senha"
                                    autoComplete="off"
                                    register={register({ required: true })}
                                    defaultValue={representative ? representative.password : ''}
                                />
                                {errors.passwordUser && <FeedbackError message={Constants.MSG_REQUIRED_FIELD} />}
                            </div>
                            <div className="form-group col-12 col-md-6">
                                <Input id="phone"
                                    name="phone"
                                    placeholder="Telefone"
                                    description="Telefone"
                                    // maxLength={11}
                                    mask="(99)99999999"
                                    register={register({ required: true })}
                                    defaultValue={representative ? representative.phone : ''}
                                />
                                {errors.phone && <FeedbackError message={Constants.MSG_REQUIRED_FIELD} />}
                            </div>
                            <div className="form-group col-12 col-md-6">
                                <label style={{ marginBottom: 4 }} className="c-label">Faturamento Mínimo</label>
                                <CurrencyInput
                                    className="c-input form-control undefined"
                                    placeholder="R$ ..."
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix={Constants.CURRENCY_SIGNAL}
                                    value={minimumBilling}
                                    onChange={(value: string) => setMinimumBilling(Utils.removeMoneySignal(value))}
                                />
                                {errors.minimumBilling && <FeedbackError message={Constants.MSG_REQUIRED_FIELD} />}
                            </div>
                            <div className="form-group col-12 col-md-6">
                                <Select
                                    value={group}
                                    value_key='id'
                                    label_key='name'
                                    options={groups}
                                    className="form-control"
                                    readOnly={!can('UPDATE_GROUP', 'USER')}
                                    description="Selecione um grupo"
                                    blank_label="Selecione um grupo"
                                    onChange={(e) => setGroup(e?.target?.value)} />
                            </div>
                            <div className="form-group col-12 col-md-6">
                                <Select
                                    value_key='id'
                                    label_key='name'
                                    options={!can('UPDATE_PROFILE', 'USER')
                                        ? profiles?.data.filter(p => p.id == representative.profile_id)
                                        : profiles?.data}
                                    defaultValue={representative.profile_id}
                                    name="profile_id"
                                    readOnly={!can('UPDATE_PROFILE', 'USER')}
                                    className="form-control"
                                    register={register()}
                                    description="Selecione um perfil"
                                    blank_label={!can('UPDATE_PROFILE', 'USER') ? null : "Selecione um perfil"} />
                            </div>
                            <div className="form-group col-12 col-md-6" >
                                {can('MANAGE', 'PROFILE') ?
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', gap: 15, alignItems: 'flex-end', height: '100%' }}>
                                        <CheckBoxCustom checked={isMaster} description='Usuário master' change={() => setIsMaster(!isMaster)}></CheckBoxCustom>

                                    </div>
                                    : null}
                            </div>
                        </div>
                    </>
                }
            </form>
        </>
    )
}
export default RepresentativeForm
