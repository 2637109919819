/* eslint-disable object-curly-spacing */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable array-bracket-spacing */
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import Swal from 'sweetalert2'
import { connect } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'
import ModalImage from 'react-modal-image'
import Pagination from 'pagination-front-end'
import ToggleButton from 'react-toggle-button'
import withReactContent from 'sweetalert2-react-content'

import './styles.scss'
import styles from './index.module.scss'
import Constants from '../../constants'
import { UserContext } from 'contexts/user'
import { Input } from '../../components/input'
import { Select } from '../../components/select'
import { Button } from '../../components/button'
import { loadProducts } from '../../store/products'
import { PageHeader } from '../../components/page-header'
import ProductService from '../../services/ProductService'
import { FeedbackError } from '../../components/feedback/error'
import { FeedbackSuccess } from '../../components/feedback/success'
import { PaginationCustom } from '../../components/pagination-custom'
import { DirectSaleProduct } from '../../interfaces/directSaleProduct'
import ProviderProductService from 'services/v3/provider-product.service'
import { ProjectContainer } from '../../components/project-container/index'
import { AddProductToGroupModal } from './components/add-product-to-group-modal'


import { MdOutlineEdit, MdOutlineGroup, MdNoteAdd, MdEdit } from 'react-icons/md'
import { GrCubes } from 'react-icons/gr'
import { FaCubes } from 'react-icons/fa'
import { VscTrash } from 'react-icons/vsc'
import { AiOutlineDollar, AiOutlineUnorderedList, AiOutlineCloseCircle, AiOutlineCloud, AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai'
import { IoMdInformationCircle } from 'react-icons/io'
import { FaBoxes } from 'react-icons/fa'
import { BsFillCloudFill } from 'react-icons/bs'
import ButtonV3 from 'components/Buttonv3'
import InputV2 from 'components/InputV2'
import { PageHeaderV2 } from 'components/pageHeaderV2'
import { BaseModal } from 'components/BaseModal'
import { RoadMap } from 'components/roadmap'
import { SelectV2 } from 'components/selectV2'
import clubProductService from 'services/v3/clubProductsService'
import PaginationCustomV2 from 'components/PaginationCustomV2'
import { CheckBoxCustom } from 'components/checkBoxCustom'
import { Loader } from 'components/loader'
import { BrandsModal } from './brandsModal'
import ReactSwitch from 'react-switch'
import { toast, ToastContainer } from 'react-toastify'
import { CategoriesModal } from './categoriesModal'
import { Description } from '../../components/newPagination/styles';





const Products = ({ products, findProducts }: any) => {

  const MySwal = withReactContent(Swal)
  const { user } = useContext(UserContext)

  const pageSize = 15
  const [productsVisible, setProductsVisible] = useState(true)
  const [reload, setReload] = useState(true)

  const [clubProductsCategories, setClubProductsCategories] = useState([])
  const [clubProducts, setClubProducts] = useState<any>()


  const [group, setGroup] = useState<any>()
  const [error, setError] = useState('')
  const [filter, setFilter] = useState('')
  const [allPages, setAllPages] = useState(1)
  const [loading, setLoading] = useState(true)
  const [loadingTable, setLoadingTable] = useState(false)
  const [isGroupFilterDisabled, setDisableGroupFilter] = useState(true)
  const [groups, setGroups] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [productsItems, setProductsItems] = useState([])
  const [product, setProduct] = useState<DirectSaleProduct>()
  const [loadClear, setLoadClear] = useState(false)
  const [brandsModalVisible, setBrandsModalVisible] = useState(false)
  const [categoriesModalVisible, setCategoriesModalVisible] = useState(false)
  const [selectedItem, setSelectedItem] = useState<any>()
  const [prodCategories, setProdCategories] = useState<any>([])

  const [AscOrDesc, setAscOrDesc] = useState('asc')
  const [idFilter, setIdFilter] = useState('')
  const [categoryFilter, setCategoryFilter] = useState<any>([])


  const [successMessage, setSuccessMessage] = useState<string>('')
  const [isAddProductToGroupModalVisible, setAddProductToGroupModalVisible] = useState(false)
  const { can } = useContext(UserContext)
  const [searchParams, setSearchParams] = useSearchParams();
  const init = {
    category_id: '',
    group_id: '',
    product_name_filter: '',
    pro_ean: '',
    name: '',
    page: 1,
    filter: '',
    sort_by: 'pro_descricao',
    sort_order: 'asc',
  }
  // qtdEmUso
  // asc
  //pro_descricao
  // const [params, setParams] = useState<any>()
  const [params, setParams] = useState<any>(() => {
    const savedParams = localStorage.getItem('productsParams');
    return savedParams ? JSON.parse(savedParams) : init;
  });

  useEffect(() => { LoadClubProducts() }, [reload])
  useEffect(() => { setParams({ ...params, group_id: group }) }, [group])
  useEffect(() => {
    localStorage.setItem('productsParams', JSON.stringify(params));
  }, [params]);

  useEffect(() => {

    const urlparams = new URLSearchParams(location.search);
    const fresh = urlparams.get('fresh') === '1';
    let actualParams = { ...params }

    if (user) {

      setLoading(true)
      // setGroup(user.groupId)
      setDisableGroupFilter(!!user.groupId)
      ProductService.groups(user.providerId).then((result) => setGroups(result.groups))
      if (fresh) {
        findProducts(user.providerId)

        urlparams.delete('fresh')
        setParams(init)
        // (async () => {
        //   const rlparams = new URLSearchParams(searchParams);
        //   rlparams.delete('fresh');
        //   await setSearchParams(rlparams);
        // })();

      } else {

        findProducts(user.providerId, {
          product_name: params.product_name_filter,
          group: params.group_id,
          pro_ean: params.pro_ean,
          page: params.page,
          category: categoryFilter?.length > 0 ? categoryFilter[0] : null,
          subcategory: categoryFilter?.length == 2 ? categoryFilter[1] : null

        })
      }

    }


    // if (user && !isStatic) {

    //   setLoading(true)
    //   setGroup(user.groupId)
    //   setDisableGroupFilter(!!user.groupId)
    //   findProducts(user.providerId, { group: user.groupId })

    // }
    // if(user){

    //     ProductService.groups(user.providerId).then((result) => setGroups(result.groups))
    // }
  }, [user, findProducts])

  useLayoutEffect(() => {
    !productsVisible && (
      LoadClubProducts(),
      LoadClubProductsCategory()
    )
  }, [productsVisible, AscOrDesc])

  async function LoadClubProductsCategory() {
    clubProductService.loadCategories().then(res => {
      setClubProductsCategories(res)
    })
  }
  async function LoadClubProducts() {
    setLoadingTable(true)
    clubProductService.loadClubProducts(params).then(res => {
      setClubProducts(res)
    }).then(res => setLoadingTable(false))
  }
  function handleSelectPage(page) {
    setParams({ ...params, page: page })
    setReload(!reload)

  }
  function cleanFiltersClub() {
    setParams(init)
    setReload(!reload)
  }

  // useEffect(() => {

  //   checkFilters() //?aqui
  // }, [loadClear])

  useEffect(() => {

    setLoading(false)

    products && products.produtos.length > 0 &&
      setProductsItems(products.produtos)

  }, [products])

  useEffect(() => {
    handleGetAllProdCategories()
    if (products) {

      products && products.produtos.length > 0 &&
        setProductsItems(products.produtos)


    }
  }, [])

  function handlePageChange(page) {
    setLoading(true)
    setParams({ ...params, page: page })
    // checkFilters(page)
    checkFilters(page)

  }

  const _handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setFilter(e.target.value)
    }
  }

  const confirm = (product: DirectSaleProduct) => {
    MySwal.fire({
      title: 'Você quer excluir este produto?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        ProductService.removeProduct(user.providerId, product.id).then(() => {
          setSuccessMessage(Constants.MSG_PRODUCT_SUCCESS_REMOVAL)
          setTimeout(() => { setSuccessMessage('') }, 5000)
          findProducts(user.providerId)
        }).catch(() => {
          setError(Constants.MSG_PRODUCT_ERROR_REMOVAL)
          setTimeout(() => { setError('') }, 5000)
        })
      }
    })
  }

  function checkFilters(page?) {
    // const init = {
    //   category_id: '',
    //   group_id: '',
    //   product_name_filter:'',
    //   pro_ean:'',
    //   name: '',
    //   page: 1,
    //   filter: '',
    //   sort_by: 'pro_descricao',
    //   sort_order: 'asc',
    // }


    let grupo = group == 1 ? undefined : group
    if (user) {

      findProducts(user.providerId,
        {
          product_name: params.product_name_filter,
          group: params.group_id,
          pro_ean: params.pro_ean,
          page: page ? page : params.page,
          category: categoryFilter?.length > 0 ? categoryFilter[0] : null,
          subcategory: categoryFilter?.length == 2 ? categoryFilter[1] : null

        })
      // findProducts(user.providerId,
      //   {
      //     product_name: filter,
      //     group: grupo,
      //     pro_ean: idFilter,
      //     page: page,
      //     category: categoryFilter?.length > 0 ? categoryFilter[0] : null,
      //     subcategory: categoryFilter?.length == 2 ? categoryFilter[1] : null

      //   })
      // }
    }
  }

  function cleanFilters() {

    // setFilter('')
    // setIdFilter('')
    // setCategoryFilter([])
    // setGroup(1)
    // localStorage.setItem('productsParams', JSON.stringify(params));
    localStorage.removeItem('productsParams')
    setCategoryFilter([])
    setParams(init)
    // setLoadClear(!loadClear)
    if (user) {
      findProducts(user.providerId)
    }

  }


  const update_status = (product: DirectSaleProduct) => {
    MySwal.fire({
      title: product.status ? 'Desativar produto?' : 'Ativar produto?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        ProviderProductService.update_status(product.id, !product.status).then(() => {
          toast.success('Produto atualizado com sucesso')
          // setSuccessMessage("Produto atualizado com sucesso")
          // setTimeout(() => { setSuccessMessage('') }, 5000)
          findProducts(user.providerId)
        }).catch(() => {
          toast.error('Produto atualizado com sucesso')
          // setError("Houve um erro ao desativar o produto")
          // setTimeout(() => { setError('') }, 5000)
        })
      }
    })
  }

  const openAddProductToGroupModal = (product: DirectSaleProduct) => {
    setProduct(product)
    setAddProductToGroupModalVisible(true)
  }

  async function handleFilterLoadClubProducts() {
    await setParams({ ...params, page: 1 })
    setReload(!reload)
  }



  async function handleSetOrderByQuantity(status) {
    // await setParams({ ...params, sort_order: params.sort_order == 'asc' ? 'desc' : 'asc', sort_by: 'emUso' })
    await setParams({ ...params, sort_by: status ? 'emUso' : 'pro_descricao', sort_order: status ? 'desc' : 'asc' })
    setReload(!reload)
  }
  async function handleSetOrderDescription() {
    await setParams({ ...params, sort_order: params.sort_order == 'asc' ? 'desc' : 'asc', sort_by: 'pro_descricao' })
    setReload(!reload)
  }

  function handleOpenBrandsModal(item) {
    setSelectedItem(item)
    setBrandsModalVisible(true)

  }

  function handleOpenCategoriesModal() {
    setCategoriesModalVisible(true)
  }

  async function handleGetAllProdCategories() {
    await ProductService.getAllProductCategories().then(res => {
      setProdCategories(res.data?.sort((a, b) => a.name.localeCompare(b.name)))
    })
  }

  function returnCategoryName(cat, subcat) {
    let categ = prodCategories.filter(item => item.id == cat)[0]
    if (subcat && categ) {
      let subcateg = categ.subCategories.filter(item => item.id == subcat)[0]

      return `${categ.name} - ${subcateg?.name} `
    } else if (categ) {
      return categ.name
    }

  }
  function handleSetCategoryFilter(selectedfilter) {
    setParams({...params,page:1})
    let selectedCategorySplited = selectedfilter.split('-')
    setCategoryFilter(selectedCategorySplited)

    // if (selectedCategorySplited.length == 2){
    //   setCategoryFilter(selectedCategorySplited[1])
    // }else{
    //   setCategoryFilter(selectedCategorySplited[0])
    // }


  }

  return (
    <ProjectContainer loading={false}>

      <div className="products">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable

          pauseOnHover />
        <RoadMap
          pageLinks={[
            {
              title: 'Produtos',
              onclick: () => { }
            },
          ]}
        ></RoadMap>
        <header>
          <PageHeaderV2 title="Produtos" />
        </header>

        <div >
          <div style={{ 'display': 'flex', 'flexDirection': 'column' }} >
            <section className='sectionTOp' style={{
              'display': 'flex',
              'flexDirection': 'row',
              'marginBottom': 25,
              'flexWrap': 'wrap',
              'justifyContent': 'space-between'

            }}>
              {
                // ------------------<>---------
              }

              <div className="nav nav-tabs navtabProdCustom" style={{ borderBottom: 'none' }}>
                <a onClick={() => setProductsVisible(true)}
                  className={
                    productsVisible
                      ? 'nav-item ,nav-link, active'
                      : 'nav-item ,nav-link'
                  }

                >
                  <FaBoxes />
                  Meus Produtos
                </a>
                <a onClick={() => setProductsVisible(false)}
                  className={
                    !productsVisible
                      ? 'nav-item ,nav-link, active'
                      : 'nav-item ,nav-link'
                  }
                >
                  <BsFillCloudFill />
                  Produtos do Club
                </a>
              </div>

              {
                // ------------------<>---------
              }
              <div className='topButtonsProducts'>
                {can('MANAGE_GROUPS', 'PRODUCT') &&
                  <div style={{ 'minWidth': 120 }}>
                    <Link to={Constants.ROUTE_GROUP_PRODUCTS}>
                      <ButtonV3
                        // onClick={() => { }}
                        title='Grupo de produtos'
                        Icon={<MdOutlineGroup className='iconButtonV3' />}
                      />
                    </Link>
                  </div>
                }

                {can('CREATE', 'PRODUCT') &&
                  <div style={{ 'minWidth': 80 }}>

                    <ButtonV3
                      onClick={() => handleOpenCategoriesModal()}
                      title='Categorias'
                      Icon={<MdNoteAdd className='iconButtonV3' />}
                    />

                  </div>
                }

                {can('CREATE', 'PRODUCT') && <Link to={Constants.ROUTE_PRODUCTS_ADD}>
                  <ButtonV3
                    // onClick={() => { }}
                    title='Novo Produto'
                    Icon={<MdNoteAdd className='iconButtonV3' />}
                  />
                </Link>}
              </div>
            </section>
            {productsVisible ?

              <section className='sectionProductsFiltes'>

                <div style={{

                  'display': 'flex',
                  'flexDirection': 'row',
                  'alignItems': 'flex-end',
                  'justifyContent': 'space-between',
                  'width': '100%',
                  'flexWrap': 'wrap',
                  gap: 20

                }} >
                  <div style={{
                    'display': 'flex',
                    'flexDirection': 'row',
                    'alignItems': 'flex-end',
                    'flexWrap': 'wrap',
                    gap: 15
                  }} >


                    <div className='inputItemProductPage' >
                      <InputV2
                        infoIconNull
                        icon={<MdEdit />}
                        mask={undefined}

                        id={'Filtrar por nome'}
                        label={'Filtrar por nome'}
                        // value={filter}
                        value={params.product_name_filter}
                        type={'text'}
                        placeholder={'Nome do produto'}
                        // onChange={(e) => { setFilter(e.target.value) }}
                        onChange={(e) => { setParams({ ...params,page:1, product_name_filter: e.target.value }) }}

                      />
                    </div>
                    <div className='inputItemIdFilter'  >
                      <InputV2
                        infoIconNull
                        icon={<MdEdit />}
                        mask={undefined}

                        id={'Filtrar por ID'}
                        label={'Filtrar por ID'}
                        // value={idFilter}
                        value={params.idFilter}
                        type={'text'}
                        placeholder={'Código'}
                        // onChange={(e) => { setIdFilter(e.target.value) }}
                        onChange={(e) => { setParams({ ...params,page:1, pro_ean: e.target.value }) }}


                      />
                    </div>

                    {
                      //    <Input
                      //        type="text"
                      //        onKeyDown={_handleKeyDown}
                      //        description="Filtrar por nome"
                      //        className="form-control input-field"
                      //        placeholder="Aperte enter para pesquisar"
                      //    />
                    }



                    <div style={{ width: 170 }} className="containerSelectProductPage">
                      <div className={'labelContainer'}>
                        <label style={{ marginBottom: 4 }} id={'123'} className="label" >Filtrar por grupo </label>
                      </div>
                      <div className="selectContainer">
                        <select
                          name="select"
                          className="inputSelect"
                          // onChange={(e: any) => setGroup(e?.target?.value)}
                          onChange={(e: any) => setParams({ ...params,page:1, group_id: e?.target?.value })}
                        >
                          <option value={1} >Selecione um grupo</option>
                          {
                            groups && groups.map(item => {
                              return (
                                <option selected={params.group_id == item.id} value={item.id}>{item.name}</option>
                              )
                            }
                            )
                          }
                        </select>
                      </div>
                    </div>

                    <div className="containerSelectProductPage">
                      <div className={'labelContainer'}>
                        <label style={{ marginBottom: 4 }} id={'123'} className="label" >Filtrar por categoria </label>
                      </div>
                      <div className="selectContainer">


                        <select
                          name="select"
                          className="inputSelect"
                          style={{ textTransform: 'capitalize' }}
                          onChange={(e: any) => handleSetCategoryFilter(e?.target?.value)}
                        >
                          <option selected={categoryFilter == undefined} value={undefined}>categoria</option>
                          {
                            prodCategories && prodCategories.map(item => {
                              return (
                                <>
                                  <option className='itemOption' selected={categoryFilter[0] == item.id} value={item.id}>{item.name}</option>
                                  {item.subCategories?.length > 0 ?
                                    item.subCategories.map(sub => {
                                      return <option className='subItemOption'
                                        selected={categoryFilter.length == 2 && categoryFilter[1] == sub.id} value={item.id + '-' + sub.id}>{sub.name}</option>
                                    })
                                    : null}
                                </>
                              )
                            }
                            )
                          }
                        </select>

                      </div>
                    </div>

                  </div>
                  <div style={{
                    'display': 'flex',
                    'flexDirection': 'row',
                    // 'minWidth': '25%',
                    //'marginTop': 15,
                    'justifyContent': 'flex-end',
                    gap: 20



                  }}>
                    <div style={{
                      //'marginRight': 20
                    }}>
                      <ButtonV3
                        title='Limpar Filtro'
                        Icon={<AiOutlineCloseCircle className='iconButtonV3' />}
                        onClick={() => { cleanFilters() }}
                      />
                    </div>
                    <div >
                      <ButtonV3
                        title='Filtrar'
                        filled
                        onClick={() => { checkFilters() }}
                      />
                    </div>
                  </div>
                </div>
                <div >
                  {
                    //    <Select
                    //        value={group}
                    //        value_key='id'
                    //        label_key='name'
                    //        options={groups}
                    //        className="form-control"
                    //        disabled={isGroupFilterDisabled}
                    //        description="Filtrar por grupo"
                    //        blank_label="Selecione um grupo"
                    //        onChange={(e) => setGroup(e?.target?.value)} />
                  }
                </div>
              </section>

              :
              <section>
                <div style={{

                  'display': 'flex',
                  'flexDirection': 'row',
                  'alignItems': 'flex-end',
                  'justifyContent': 'space-between',
                  'width': '100%',
                  'flexWrap': 'wrap',
                  gap: 20

                }} >
                  <div style={{
                    'display': 'flex',
                    'flexDirection': 'row',
                    'alignItems': 'flex-end',
                    'flexWrap': 'wrap',
                    gap: 15
                  }} >


                    <div className='inputItemProductPage' >

                      <InputV2
                        infoIconNull
                        icon={<MdEdit />}
                        id={'Filtrar por nome'}
                        label={'Filtrar por nome'}
                        value={params.name}
                        type={'text'}
                        placeholder={'Nome do produto'}
                        onChange={(e) => { setParams({ ...params, name: e.target.value }) }}
                      />
                    </div>

                    {
                      //    <Input
                      //        type="text"
                      //        onKeyDown={_handleKeyDown}
                      //        description="Filtrar por nome"
                      //        className="form-control input-field"
                      //        placeholder="Aperte enter para pesquisar"
                      //    />
                    }



                    {/* <div className="containerSelectProductPage">
                                            <div className={'labelContainer'}>
                                                <label id={'123'} className="label" >Filtrar por categoria </label>
                                            </div>
                                            <div className="selectContainer">
                                                <select
                                                    name="select"
                                                    className="inputSelect"
                                                    onChange={(e: any) => setGroup(e?.target?.value)}
                                                >
                                                    <option value={1} >Selecione um grupo</option>
                                                    {
                                                        groups && groups.map(item => {
                                                            return (
                                                                <option selected={group == item.id} value={item.id}>{item.name}</option>
                                                            )
                                                        }
                                                        )
                                                    }
                                                </select>
                                            </div>
                                        </div> */}

                    {clubProductsCategories && clubProductsCategories.length > 0 ?
                      <div style={{ width: 180 }}>
                        <SelectV2
                          description='Categoria'
                          onChange={(e: any) =>
                            setParams({ ...params, category_id: e.target.value })
                          }
                          options={clubProductsCategories && clubProductsCategories}
                          value={params.category_id}
                          blank_label="Categoria"
                          value_key="id"
                          label_key="name"
                        />
                      </div>

                      : null}
                    <div>
                      <div style={{ marginBottom: 5 }}>

                        <CheckBoxCustom
                          // value={params.filter}
                          change={(e, stats) => handleSetOrderByQuantity(stats)}
                          checked={params.sort_by == 'emUso'}
                          description={'Ordenar por qntd. de requisições'}
                        ></CheckBoxCustom>
                      </div>
                      <CheckBoxCustom
                        value={params.filter}
                        change={(e, stats) => stats ?
                          setParams({ ...params, filter: 's' }) :
                          setParams({ ...params, filter: '' })}
                        checked={params.filter == 's'}
                        description={'Apenas itens em uso'}
                      ></CheckBoxCustom>

                    </div>
                  </div>
                  <div style={{
                    'display': 'flex',
                    'flexDirection': 'row',
                    // 'minWidth': '25%',
                    //'marginTop': 15,
                    'justifyContent': 'flex-end',
                    gap: 10

                  }}>
                    <div style={{
                      //'marginRight': 20
                    }}>
                      <ButtonV3
                        title='Limpar Filtro'
                        Icon={<AiOutlineCloseCircle className='iconButtonV3' />}

                        onClick={() => { cleanFiltersClub() }}
                      />
                    </div>
                    <div >
                      <ButtonV3
                        title='Filtrar'
                        filled
                        onClick={() => { handleFilterLoadClubProducts() }}
                      />
                    </div>
                  </div>
                </div>
                <div >
                  {
                    //    <Select
                    //        value={group}
                    //        value_key='id'
                    //        label_key='name'
                    //        options={groups}
                    //        className="form-control"
                    //        disabled={isGroupFilterDisabled}
                    //        description="Filtrar por grupo"
                    //        blank_label="Selecione um grupo"
                    //        onChange={(e) => setGroup(e?.target?.value)} />
                  }
                </div>
              </section>
            }

          </div>
        </div>
        {loading ? <Loader ></Loader> : null}
        {error && <FeedbackError message={error} />}

        {successMessage && <FeedbackSuccess message={successMessage} />}
        {productsVisible &&

          <div className="NewlistProducts">
            <table className={'newTableProducts'}>
              <thead>
                <tr>
                  <th>Imagem</th>
                  <th>Código</th>
                  <th>Descrição</th>
                  <th>Embalagem</th>
                  <th>Qtd. Embalagem</th>
                  <th>Limite diário</th>
                  <th>Estoque</th>
                  <th className="actions">Ações</th>
                </tr>
              </thead>
              <tbody style={{height:'100%'}}>
                {productsItems?.length > 0 && productsItems.map((item: DirectSaleProduct, index) => (
                  <>
                    <tr style={index % 2 == 0 ? { backgroundColor: '#fff' } : {}} className='desktopTr' key={item.id}>
                      <td data-label='Imagem'>
                        <ModalImage
                          small={item.picture || 'https://raw.githubusercontent.com/jhony2488/images/master/image-not.png'}
                          large={item.picture || 'https://raw.githubusercontent.com/jhony2488/images/master/image-not.png'}
                          className={`${styles.imageProduct}`}
                          alt={`Produto: ${item.description}`}
                          hideZoom={true}
                          showRotate={true}
                        />
                      </td>
                      <td data-label='Código'>{item.ean}</td>
                      <td data-label='Descrição'><div className='descColumProd'>
                        <p>{item.description}</p>
                        {prodCategories?.length > 0 && item.categoria ?
                          // <span>{returnCategoryName(16421,8)}</span>
                          <span>{returnCategoryName(item.categoria, item.subcategoria)}</span>
                          : null}
                      </div>
                      </td>
                      <td data-label='Embalagem'>{item.packing.split(' ')[0]}</td>
                      <td data-label='Qtd. Embalagem'>{item.packingQuantity}</td>
                      <td data-label='Limite diário'>{item.dailyLimit ? item.dailyLimit : '--'}</td>
                      <td data-label='Estoque'>{item.stock ? item.stock : '--'}</td>
                      <td data-label='Ações'>
                        <div
                          className={'listActionsProducts'}
                        >
                          {can('UPDATE', 'PRODUCT') &&
                            <div
                            //className={styles.tooltip}
                            >
                              <Link to={`${Constants.ROUTE_PRODUCTS_EDIT}${item.id}`}>
                                <MdOutlineEdit
                                  title='Editar Produto'
                                  style={{
                                    'color': '#606060',
                                    'fontSize': 24
                                  }} />

                              </Link>
                              {
                                //  <span className={styles.tooltiptext}>Editar Produto</span>
                              }
                            </div>}
                          {can('PRICING', 'PRODUCT') && <div className={styles.tooltip}>
                            <Link to={`${Constants.ROUTE_PRODUCTS_EDIT_PRICE_LEVEL}${item.id}`}>
                              <AiOutlineDollar
                                title='Níveis de preço'
                                style={{
                                  'color': '#30AA4C',
                                  'fontSize': 24
                                }} />
                            </Link>
                            {
                              //  <span className={styles.tooltiptext}>Níveis de preço</span>
                            }
                          </div>}
                          {can('MANAGE_GROUPS', 'PRODUCT') && <div className={styles.tooltip}>
                            <AiOutlineUnorderedList
                              title={'Grupo de produtos'}
                              onClick={() => openAddProductToGroupModal(item)}
                              style={{
                                'color': '#17A2B8',
                                'fontSize': 24,
                                'cursor': 'pointer'
                              }} />
                            {
                              //   <span className={styles.tooltiptext}>Grupo de produtos</span>
                            }
                          </div>}
                          {can('DELETE', 'PRODUCT') && <div className={styles.tooltip}>
                            <VscTrash
                              title='Excluir Produto'
                              onClick={() => confirm(item)}
                              style={{
                                'cursor': 'pointer',
                                'color': '#CD2A2A',
                                'fontSize': 24
                              }} />

                            {
                              //  <span className={styles.tooltiptext}>Excluir Produto</span>
                            }
                          </div>}
                          {can('ACTIVATE_DEACTIVATE', 'PRODUCT') && <div className={styles.tooltip}>
                            <ToggleButton

                              trackStyle={{}}
                              inactiveLabel={<p className='switchButtonLabel'>Inativo</p>}

                              activeLabel={<p className='switchButtonLabel'>Ativo</p>}
                              colors={{
                                activeThumb: {
                                  base: 'white',
                                },
                                inactiveThumb: {
                                  base: 'white',
                                },
                                active: {
                                  base: '#FF7110',
                                  hover: '#FF7110',
                                },
                                inactive: {
                                  base: '#e2e2e2',
                                  hover: '#d9d9d9',
                                }
                              }}
                              value={item.status}
                              onToggle={() => {
                                update_status(item)
                              }} />
                            {

                              //    <span
                              //        className={styles.tooltiptext}>
                              //        {item.status ? 'Desativar Produto' : 'Ativar Produto'}
                              //    </span>
                            }
                          </div>}
                        </div>
                      </td>
                    </tr>
                    <tr style={{ padding: 0 }} className='mobileTr' key={item.id + 'mobile'}>
                      <td className='firstTdMobile'>
                        <div className='headerMobileTable' >
                          {item.description}
                        </div>
                        {/* <ModalImage
                          small={item.picture || 'https://raw.githubusercontent.com/jhony2488/images/master/image-not.png'}
                          large={item.picture || 'https://raw.githubusercontent.com/jhony2488/images/master/image-not.png'}
                          className={`${styles.imageProduct}`}
                          alt={`Produto: ${item.description}`}
                          hideZoom={true}
                          showRotate={true}
                        /> */}
                      </td>
                      <td>
                        <div className='rowBetween'>
                          <ModalImage
                            small={item.picture || 'https://raw.githubusercontent.com/jhony2488/images/master/image-not.png'}
                            large={item.picture || 'https://raw.githubusercontent.com/jhony2488/images/master/image-not.png'}
                            className={`imageProductMobileTr`}
                            alt={`Produto: ${item.description}`}
                            hideZoom={true}
                            showRotate={true}
                          />
                          <section style={{ marginLeft: 10 }} className='sectionMobileTrProd'>
                            <div style={{ gridTemplateColumns: '1fr 1fr 1.2fr' }} className='rowBetweenGrid'>
                              <div style={{ alignItems: 'flex-start' }} className='infoStackMobileTable'>
                                <p>Código</p>
                                <span>{item.ean}</span>
                              </div>
                              <div className='infoStackMobileTable'>
                                <p>Embalagem:</p>
                                <span>{item.packing.split(' ')[0]}</span>
                              </div>
                              <div style={{}} className='infoStackMobileTable'>
                                <p>Qtd.Embalagem</p>
                                <span >{item.packingQuantity}</span>
                              </div>
                            </div>
                            <div style={{ gridTemplateColumns: '1fr 1fr 1.4fr' }} className='rowBetweenGrid' >
                              <div style={{ alignItems: 'flex-start' }} className='infoStackMobileTable'>
                                <p>Limite diário</p>
                                <span>{item.dailyLimit ? item.dailyLimit : '--'}</span>
                              </div>
                              <div className='infoStackMobileTable'>
                                <p>Estoque:</p>
                                <span>{item.stock ? item.stock : '--'}</span>
                              </div>
                              {prodCategories?.length > 0 && item.categoria ?

                                <div className='infoStackMobileTable'>
                                  <p>Categoria:</p>
                                  {/* <span style={{textAlign:'center'}}>{returnCategoryName(16421,8)}</span> */}
                                  <span style={{ textAlign: 'center' }}>{returnCategoryName(item.categoria, item.subcategoria)}</span>
                                </div>
                                : null}


                            </div>
                          </section>
                        </div>
                      </td>
                      <td>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {can('ACTIVATE_DEACTIVATE', 'PRODUCT') &&
                              <div >
                                <ReactSwitch
                                  onChange={() => {
                                    update_status(item)
                                  }}
                                  checked={!!item.status}
                                  onColor={'#FF7110'}
                                  width={80}
                                  uncheckedIcon={
                                    <div className='spanSwitchProdInvactive'>
                                      <span >Inativo</span>
                                    </div>
                                  }
                                  checkedIcon={
                                    <div className='spanSwitchProdActive'>
                                      <span>Ativo</span>
                                    </div>
                                  }
                                // offColor={}
                                />

                                {/* <ToggleButton
                                    style={{width:'130px !important'}}
                                    trackStyle={{width:'130px !important'}}
                                    thumbStyle={{}}
                                    inactiveLabel={<p className='switchButtonLabel'>Inativo</p>}

                                    activeLabel={<p className='switchButtonLabel'>Ativo</p>}
                                    colors={{
                                      activeThumb: {
                                        base: 'white',
                                      },
                                      inactiveThumb: {
                                        base: 'white',
                                      },
                                      active: {
                                        base: '#FF7110',
                                        hover: '#FF7110',
                                      },
                                      inactive: {
                                        base: '#e2e2e2',
                                        hover: '#d9d9d9',
                                      }
                                    }}
                                    value={item.status}
                                    onToggle={() => {
                                      update_status(item)
                                    }} /> */}
                                {

                                  //    <span
                                  //        className={styles.tooltiptext}>
                                  //        {item.status ? 'Desativar Produto' : 'Ativar Produto'}
                                  //    </span>
                                }
                              </div>}

                          </div>
                          <div
                            className={'divActionsMobileTable'}
                          >
                            {can('UPDATE', 'PRODUCT') &&
                              <div
                                className={'buttonActionMobileTable'}
                              >
                                <Link to={`${Constants.ROUTE_PRODUCTS_EDIT}${item.id}`}>
                                  <MdOutlineEdit
                                    title='Editar Produto'
                                    style={{
                                      'color': '#606060',
                                      'fontSize': 24
                                    }} />

                                </Link>
                                {
                                  //  <span className={styles.tooltiptext}>Editar Produto</span>
                                }
                              </div>}
                            {can('PRICING', 'PRODUCT') && <div className={'buttonActionMobileTable'}>
                              <Link to={`${Constants.ROUTE_PRODUCTS_EDIT_PRICE_LEVEL}${item.id}`}>
                                <AiOutlineDollar
                                  title='Níveis de preço'
                                  style={{
                                    'color': '#30AA4C',
                                    'fontSize': 24
                                  }} />
                              </Link>
                              {
                                //  <span className={styles.tooltiptext}>Níveis de preço</span>
                              }
                            </div>}
                            {can('MANAGE_GROUPS', 'PRODUCT') && <div className={'buttonActionMobileTable'}>
                              <AiOutlineUnorderedList
                                title={'Grupo de produtos'}
                                onClick={() => openAddProductToGroupModal(item)}
                                style={{
                                  'color': '#17A2B8',
                                  'fontSize': 24,
                                  'cursor': 'pointer'
                                }} />
                              {
                                //   <span className={styles.tooltiptext}>Grupo de produtos</span>
                              }
                            </div>}
                            {can('DELETE', 'PRODUCT') && <div className='buttonActionMobileTable'>
                              <VscTrash
                                title='Excluir Produto'
                                onClick={() => confirm(item)}
                                style={{
                                  'cursor': 'pointer',
                                  'color': '#CD2A2A',
                                  'fontSize': 24
                                }} />

                              {
                                //  <span className={styles.tooltiptext}>Excluir Produto</span>
                              }
                            </div>}
                            {/* {can('ACTIVATE_DEACTIVATE', 'PRODUCT') && <div className={styles.tooltip}>
                            <ToggleButton

                              trackStyle={{}}
                              inactiveLabel={<p className='switchButtonLabel'>Inativo</p>}

                              activeLabel={<p className='switchButtonLabel'>Ativo</p>}
                              colors={{
                                activeThumb: {
                                  base: 'white',
                                },
                                inactiveThumb: {
                                  base: 'white',
                                },
                                active: {
                                  base: '#FF7110',
                                  hover: '#FF7110',
                                },
                                inactive: {
                                  base: '#e2e2e2',
                                  hover: '#d9d9d9',
                                }
                              }}
                              value={item.status}
                              onToggle={() => {
                                update_status(item)
                              }} />
                            {

                              //    <span
                              //        className={styles.tooltiptext}>
                              //        {item.status ? 'Desativar Produto' : 'Ativar Produto'}
                              //    </span>
                            }
                          </div>} */}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>

            {products && products.last_page > 1 ?
              <PaginationCustom
                total={products.last_page - 1}
                itemsName={'produtos'}
                ItemsPorPage={products.per_page}
                currentPage={products.current_page}
                onPageChange={handlePageChange}
                totalItems={products.total}
              />
              : null}
          </div>
        }
        {!productsVisible &&
          <div className="NewlistProducts">
            <table style={{ flex: 1 }} className={'newTableProducts'}>
              <thead>
                <tr>
                  <th>Imagem</th>
                  <th>Código</th>
                  <th
                    className='ThClubProductsOrderby'
                    onClick={() => { }}>Descrição</th>
                  <th >Embalagem</th>
                  <th
                    className='ThClubProductsOrderby'
                    onClick={() => { }}>Qntd Requisições </th>
                  {/* <th className='text-right'>status</th> */}
                  <th className='text-right'>ações</th>
                </tr>
              </thead>
              {loadingTable && <Loader />}
              {/* <div className='ContainerButtonsMobileSetOrderBy'>
                                ordernar por:
                                <div onClick={() => handleSetOrderDescription()} className='ButtonMobileSetOrderBy'>Nome{params.sort_order == 'desc' ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}</div>
                            <div onClick={() => {}} className='ButtonMobileSetOrderBy'>Requisições{params.sort_order == 'desc' ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}</div>

                            </div> */}
              <tbody>
                {clubProducts && clubProducts.data && clubProducts.data.map((item, index) => (
                  <>
                    <tr style={index % 2 == 0 ? { backgroundColor: '#fff' } : {}} className='desktopTr'>
                      <td data-label='Imagem'>
                        <ModalImage
                          small={item.pro_imagem || 'https://raw.githubusercontent.com/jhony2488/images/master/image-not.png'}
                          large={item.pro_imagem || 'https://raw.githubusercontent.com/jhony2488/images/master/image-not.png'}
                          className={`${styles.imageProduct}`}
                          alt={`Produto: ${item.name}`}
                          hideZoom={true}
                          showRotate={true}
                        />
                      </td>
                      <td data-label='Código' className='TdCodigoClubProducts' >{item.ean}</td>
                      <td data-label='Descrição' >{item.name}</td>
                      <td data-label='Embalagem' >{item.is_generic ? item.purchase_unit : item.packing}</td>
                      <td data-label='Qntd Requisições' >{item.emUso ? item.emUso : '-'}</td>
                      {/* <td data-label='status' style={{ color: '#187C3B', fontWeight: 500, }} className='text-right'>{item.emUso == 1 ? 'Em Uso' : '- -'}</td> */}
                      <td data-label='ações' className='text-right'>
                        {item.derivatives.length > 0 ?
                          <FaCubes size={22} title='Marcas' style={{ cursor: 'pointer', marginRight: 10 }} color='#55b5c7' onClick={() => handleOpenBrandsModal(item)}>
                          </FaCubes>
                          : null}
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: 'rgba(0,0,0,0.03)', padding: 0 }} className='mobileTr'>
                      <td className='firstTdMobile'>
                        <div className='headerMobileTable' >
                          {/* <ModalImage
                            small={item.pro_imagem || 'https://raw.githubusercontent.com/jhony2488/images/master/image-not.png'}
                            large={item.pro_imagem || 'https://raw.githubusercontent.com/jhony2488/images/master/image-not.png'}
                            className={`${styles.imageProduct}`}
                            alt={`Produto: ${item.name}`}
                            hideZoom={true}
                            showRotate={true}
                          /> */}
                          <p>
                            {item.name}
                          </p>
                        </div>
                      </td>
                      {/* <td data-label='Código' className='TdCodigoClubProducts' >{item.ean}</td> */}
                      <td >
                        <div className='rowBetween'>

                          <ModalImage
                            small={item.pro_imagem || 'https://raw.githubusercontent.com/jhony2488/images/master/image-not.png'}
                            large={item.pro_imagem || 'https://raw.githubusercontent.com/jhony2488/images/master/image-not.png'}
                            className={`imageProductMobileTr`}
                            alt={`Produto: ${item.name}`}
                            hideZoom={true}
                            showRotate={true}
                          />

                          <section style={{ marginLeft: 10 }} className='sectionMobileTrProd'>
                            <div className='rowBetween'>

                              <div style={{ alignItems: 'flex-start' }} className='infoStackMobileTable'>
                                <p>Embalagem:</p>
                                <span>{item.is_generic ? item.purchase_unit : item.packing}</span>
                              </div>
                              <div style={{ marginRight: '1%' }} className='infoStackMobileTable'>
                                <p>Qntd Requisições:</p>
                                <span>{item.emUso ? item.emUso : '-'}</span>
                              </div>
                            </div>
                            <div style={{ justifyContent: 'space-between' }} className='divActionsMobileTable'>
                              <div style={{ alignItems: 'flex-start' }} className='infoStackMobileTable'>
                                <p>Código:</p>
                                <span>{item.ean}</span>
                              </div>
                              {item.derivatives.length > 0 ?
                                <div className='buttonActionMobileTable'>

                                  <FaCubes size={22} title='Marcas' style={{ cursor: 'pointer' }} color='#55b5c7' onClick={() => handleOpenBrandsModal(item)}>
                                  </FaCubes>
                                </div>
                                : null}
                            </div>

                          </section>
                        </div>
                      </td>

                      {/* <td data-label='status'
                      style={{ color: '#187C3B', fontWeight: 500, }}
                       className='text-right'>{item.emUso == 1 ? 'Em Uso' : '- -'}</td> */}
                      {/* <td data-label='ações' className='text-right'>
                        {item.derivatives.length > 0 ?
                          <FaCubes size={22} title='Marcas' style={{ cursor: 'pointer', marginRight: 10 }} color='#55b5c7' onClick={() => handleOpenBrandsModal(item)}>
                          </FaCubes>
                          : null}
                      </td> */}
                    </tr>
                  </>
                )
                )
                }

              </tbody>
            </table>
            {
              //    <PaginationCustom
              //        total={allPages}
              //        itemsName={'produtos'}
              //        ItemsPorPage={pageSize}
              //        currentPage={currentPage}
              //        onPageChange={handlePageChange}
              //        totalItems={products ? products.length : 0}
              //    />
            }
            {clubProducts && clubProducts.meta.last_page > 1 &&
              <div className='containerPaginationProducts'>
                <div>
                  <PaginationCustom
                    total={clubProducts.meta.last_page}
                    itemsName={'produtos'}
                    ItemsPorPage="15"
                    currentPage={clubProducts.meta.current_page}
                    onPageChange={(page: number) => handleSelectPage(page)}
                    totalItems={clubProducts.meta.total} />
                </div>

              </div>
            }
          </div>
        }

      </div>
      {
        //  <AddProductToGroupModal
        //             user={user}
        //             groups={groups}
        //             product={product}
        //             isVisible={isAddProductToGroupModalVisible}
        //             onClose={() => setAddProductToGroupModalVisible(false)} />
        // </ProjectContainer>
      }
      <BaseModal
        onCloseCallback={() => setAddProductToGroupModalVisible(false)}
        children={
          <AddProductToGroupModal
            user={user}
            groups={groups}
            product={product}
            isVisible={isAddProductToGroupModalVisible}
            onClose={() => setAddProductToGroupModalVisible(false)} />
        }
        title={'Escolha de Grupo de produtos'}
        user={user}
        groups={groups}
        product={product}
        isVisible={isAddProductToGroupModalVisible}
        onClose={() => setAddProductToGroupModalVisible(false)} />

      <BaseModal
        dialogClassName="modal-70w"
        onCloseCallback={() => setBrandsModalVisible(false)}
        children={
          <BrandsModal item={selectedItem} />
        }
        title={`Produto: ${selectedItem ? selectedItem?.name : ''}`}
        isVisible={brandsModalVisible}
        onClose={() => setBrandsModalVisible(false)} />

      <BaseModal
        dialogClassName="modal-70w"
        onCloseCallback={() => setCategoriesModalVisible(false)}
        children={
          <CategoriesModal />
        }
        title={`Categorias`}
        isVisible={categoriesModalVisible}
        onClose={() => setCategoriesModalVisible(false)} />
    </ProjectContainer >
  )
}

const mapStateToProps = (state: any) => ({
  user: state.user,
  products: state.products,
})

const mapDispatchToProps = (dispatch: any) => ({
  findProducts: (providerId: string, filters) => dispatch(loadProducts(providerId, filters))
})

export default connect(mapStateToProps, mapDispatchToProps)(Products)
