import { BaseModal } from 'components/BaseModal'
import { Button } from 'components/button'
import EditUserInfoForm from 'components/EditUserInfoForm'
import { FeedbackError } from 'components/feedback/error'
import { Loader } from 'components/loader'
import { PageHeader } from 'components/page-header'
import { ProjectContainer } from 'components/project-container'
import { UserContext } from 'contexts/user'
import { mask_cnpj } from 'helpers/mask.helper'
import { Customer } from 'interfaces/customer'
import { Store } from 'interfaces/store'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { FaUserTie } from 'react-icons/fa'
import { MdOutlineAssignmentLate, MdOutlineBlock, MdOutlineBadge } from 'react-icons/md'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { load_stores } from 'store/customer'
import { PaginationCustom } from '../../components/pagination-custom'
import Constants from '../../constants'
import { useNavigate } from 'react-router-dom'
import { BlacklistedModal } from './components/blacklisted-modal'
import { ClientCompaniesFilterComponent } from './components/filters'
import { MoreInformationModal } from './components/more-information-modal'
import { RegisterRequestsModal } from './components/register-requests-modal'
import { RepresentativesModal } from './components/representatives-modal'
import { WeekdayModal } from './components/weekday-modal'
import './index.scss'

import { BsTelephoneForward } from 'react-icons/bs'

import { Link } from 'react-router-dom'
import { IoMdTrash } from 'react-icons/io'
import { IoIosAddCircleOutline } from 'react-icons/io'

import { MdBlock } from 'react-icons/md'


import { AiOutlineClear } from 'react-icons/ai'
import { BsSearch, BsFillPersonPlusFill, BsCalendarDay } from 'react-icons/bs'
import NotificationBadge from 'react-notification-badge'
import ButtonV3 from 'components/Buttonv3'
import { PageHeaderV2 } from 'components/pageHeaderV2'
import { RoadMap } from 'components/roadmap'
import { CompanyDetailsModal } from './components/companyDetailsModal'
import { PhoneWithWhats } from 'components/phoneWithWhats'
import { boxShadow } from 'html2canvas/dist/types/css/property-descriptors/box-shadow'
// import { useHistory } from 'react-router-dom'



const BuyingCompanies = ({ clients, load_clients, states }: any) => {
    const initialQueryParams = {
        page: 1,
        customerId: null,
        statuses: [Constants.STATUS_ACTIVE],
        state: null,
        cnpj: '',
        name: '',
        vendedor: null,
    }

    const navigate = useNavigate()

    const location = useLocation()
    const { hash } = useLocation()
    const [show_blacklist_modal, open_blacklisted] = useState<boolean>(false)
    const [showFilters, setShowFilters] = useState<boolean>(true)
    const [queryParams, setQueryParams] = useState(initialQueryParams)
    const [weekdayModal, setWeekdayModal] = useState<boolean>()
    const [registerAmount, setRegisterAmount] = useState<number>(0)
    const [registerModal, setRegisterModal] = useState<boolean>(hash === '#rrm')
    const [loading, setLoading] = useState<boolean>(true)
    const [company, setCompany] = useState<Customer | Store | null>(null)
    const [store, setStore] = useState<Customer | Store | null>(null)
    const [modalData, setModalData] = useState<any>()
    const [modalVisible, setModalVisible] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [isSolicitation, setModalIsSolicitation] = useState(Boolean)
    const [companyDataModalVisible, setCompanyDataModalVisible] = useState(false)
    const [showClearButton, setShowClearButton] = useState(false)
    const { user, can } = useContext(UserContext)
    const iconRef = useRef<any>(null);

    const getQueryParam = (param) => {
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get(param);
    };

    const cnpj = getQueryParam('cnpj');

    useEffect(() => {
        if (cnpj && can('READ', 'DIRECT_PURCHASE')) {
            setQueryParams({ ...queryParams, name: cnpj })
        }
    }, [cnpj]);

    useEffect(() => {
        if (clients?.meta?.total == 1) {
            if (clients.data[0]?.head_office) {
                if (cnpj) {
                    autoOpenEditParams(clients.data[0]?.head_office)
                }
            }
        }
    }, [clients])

    function autoOpenEditParams(data) {
        setStore(data)
        setTimeout(() => {
            if (iconRef.current) {
                iconRef.current.click();
            }
        }, 500);
    }


    useEffect(() => {
        setLoading(true)
        if (user) {
            load_clients({
                ...queryParams,
                seller_id: can('READ_ALL', 'CLIENT_COMPANY')
                    ? queryParams.vendedor
                    : user.id,
            }).then((res) => {

                setLoading(false)
            })
        }
    }, [queryParams, user])

    const toogleFilters = () => setShowFilters(!showFilters)

    const getStatusClass = (client: any): string => {
        if (client.is_active) {
            return 'active'
        } else {
            return 'inactive'
        }
    }

    const status_name = (client: any): string => {
        if (client.is_active) {
            return 'Ativo'
        } else {
            return 'Inativo'
        }
    }

    const statusClass = (store: any): string => {
        if (store.conditions && store.conditions.length > 0) {
            return 'active'
        }

        return ''
    }

    const closeModalInformation = () => {

        const searchParams = new URLSearchParams(location.search);
        let hascnpj = searchParams.get('cnpj')
        if (hascnpj) {
            setShowClearButton(true)
        }
        searchParams.delete('cnpj');

        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        }, { replace: true });

        setCompany(null)
        if (user) {
            setLoading(true)
            load_clients({
                ...queryParams,
                seller_id: can('READ_ALL', 'CLIENT_COMPANY')
                    ? queryParams.vendedor
                    : user.id,
            }).then(() => setLoading(false))
        }
    }

    const status_name_condition = (store: Store): string => {
        if (store.count_condition !== 0) {
            return 'Ativo'
        }
        if (store.count_condition === 0) {
            return ''
        }
        return ''
    }

    const handleOpenModal = (data, isSolicitation) => {

        setModalTitle('Usuário da venda direta')
        setModalData(data)

        setModalIsSolicitation(isSolicitation)
        setModalVisible(true)
    }
    function handleOpenModalCompanyData(data) {
        setModalData(data)
        setCompanyDataModalVisible(true)
    }


    return (
        <ProjectContainer>
            <RoadMap
                pageLinks={[
                    {
                        title: 'Empresas Compradoras',
                        onclick: () => { }
                    },
                ]}
            ></RoadMap>
            <div style={{}} className="buying-companies">
                <header>

                    <PageHeaderV2 title="Empresas Compradoras" />
                </header>

                <div >
                    <div className="containerActionsButtons">
                        <div className="actionsItemButton">
                            <ButtonV3
                                Icon={<MdBlock className='iconButtonV3'></MdBlock>}
                                title='Clientes Bloqueados'
                                onClick={() => open_blacklisted(true)}
                            />
                        </div>

                        {
                            // registerAmount > 0 && (
                            //     <Button
                            //         type="button"
                            //         className="c-btn-outline"
                            //         onClick={() => setRegisterModal(true)}
                            //     >
                            //         <BsFillPersonPlusFill />
                            //         <span>
                            //             {'Solicitações de Cadastro'}
                            //         </span>
                            //         <NotificationBadge
                            //             count={registerAmount}
                            //             containerStyle={{ width: 'auto' }}
                            //             style={{ marginTop: -10, right: -20 }}
                            //         />
                            //     </Button>
                            // )
                            registerAmount > 0 && (
                                <div className="actionsItemButton">
                                    <ButtonV3
                                        title='Solicitações de Cadastro'
                                        onClick={() => setRegisterModal(true)}
                                        Icon={<IoIosAddCircleOutline className='iconButtonV3'></IoIosAddCircleOutline>}
                                    >

                                        <NotificationBadge
                                            count={registerAmount}
                                            containerStyle={{ width: 'auto' }}
                                            style={{ marginTop: -28, right: -15, backgroundColor: '#FF7110' }}
                                        />
                                    </ButtonV3>
                                </div>
                            )
                        }
                        {
                            <div className="actionsItemButton">
                                <ButtonV3
                                    title='Limpar filtros'
                                    onClick={(params: any) => setQueryParams(params)}
                                    Icon={<IoMdTrash className='iconButtonV3' />}
                                >
                                </ButtonV3>
                            </div>
                        }
                        <div className="actionsItemButton">
                            <ButtonV3
                                filled
                                title="Cadastrar"
                                onClick={() => { navigate(`${Constants.ROUTE_CLIENTS}/register`) }}>
                            </ButtonV3>
                        </div>


                        {
                            //    <Button onClick={() => toogleFilters()}>
                            //        <span className="flex items-center justify-center">
                            //            <i className="material-icons">
                            //                {showFilters ? 'close' : 'filter_alt'}
                            //            </i>
                            //            Filtros
                            //        </span>
                            //    </Button>
                        }
                    </div>
                </div>

                {
                    //  showFilters && (
                }
                {true && (
                    <ClientCompaniesFilterComponent
                        states={states}
                        customers={clients}
                        registerAmount={registerAmount}
                        onOpenRegisterModal={() => setRegisterModal(true)}
                        //onOpenWeekdayModal={ () => setWeekdayModal(true) }
                        onChange={(params: any) => setQueryParams(params)}
                    />
                )}

                {!loading ? (
                    clients && clients.meta.total > 0 ? (
                        <div className="list">
                            <table className="newTable">
                                <thead>
                                    <tr>

                                        <th>Cliente</th>
                                        <th>Nome fantasia</th>
                                        <th>CNPJ</th>
                                        <th>UF/Cidade</th>

                                        <th>Segmento</th>
                                        <th>Contato</th>
                                        {
                                            //   <th>Telefone</th>

                                        }

                                        <th className="text-center">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clients.data
                                        .filter((c) => c.head_office)
                                        .map((customer: any, idx: number) => {
                                            const stores: any = []
                                            if (
                                                customer.filials &&
                                                customer.filials.length > 0
                                            ) {
                                                customer.filials.forEach(
                                                    (store: any) => {
                                                        stores.push(
                                                            <React.Fragment key={`${store.id}-${idx}`}>
                                                                <tr className='tableSpacer'

                                                                ></tr>
                                                                <tr
                                                                // className='TesteBlue'
                                                                >

                                                                    <td data-label='Cliente' >
                                                                        <div className="headOfficeNameContainer">
                                                                            <div style={{ cursor: 'default' }}>
                                                                                <MdOutlineBadge
                                                                                    style={{ cursor: 'default' }}
                                                                                    color='transparent'
                                                                                // onClick={() => handleOpenModalCompanyData(customer)}

                                                                                />
                                                                            </div>


                                                                            {
                                                                                store.social_reazon
                                                                                    ? store.social_reazon
                                                                                    : customer.name
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td data-label='Nome fantasia'>
                                                                        {
                                                                            // store.social_reazon
                                                                            //     ? store.social_reazon
                                                                            //     : customer.name
                                                                            store.trading_name
                                                                                ? store.trading_name.toUpperCase()
                                                                                : store.social_reazon
                                                                        }
                                                                    </td>
                                                                    <td data-label='CNPJ' className="text-nowrap">
                                                                        {store.cnpj
                                                                            ? mask_cnpj(store.cnpj)
                                                                            : ''}
                                                                    </td>
                                                                    <td data-label='UF/Cidade'>
                                                                        <div style={{ 'display': 'flex', 'flexDirection': 'column' }}>
                                                                            <div style={{ 'marginBottom': 5 }}>
                                                                                {store.state.name
                                                                                    ? store.state.fu
                                                                                    : ''}

                                                                            </div>
                                                                            <div>
                                                                                {store.city.name
                                                                                    ? store.city.name
                                                                                    : ''}

                                                                            </div>

                                                                        </div>
                                                                    </td>

                                                                    <td data-label='Segmento'>
                                                                        {customer.sectors
                                                                            ? customer.sectors
                                                                                .map((s) => s.name)
                                                                                .join(',')
                                                                            : ''}
                                                                    </td>
                                                                    <td data-label='Contato'>
                                                                        <div style={{ 'display': 'flex', 'flexDirection': 'column' }}>
                                                                            <div style={{ 'marginBottom': 5 }}>

                                                                                {customer.client
                                                                                    ? customer.client.contact.toUpperCase()
                                                                                    : ''}
                                                                            </div>
                                                                            <div>
                                                                                {store.phone
                                                                                    ? <><BsTelephoneForward style={{ 'marginRight': 4 }} />{store.phone}</>
                                                                                    : ''}
                                                                            </div>

                                                                        </div>
                                                                        {
                                                                            // </td>
                                                                            // {store.phone
                                                                            //     ? store.phone
                                                                            //     : ''}
                                                                            // <td>
                                                                        }
                                                                    </td>
                                                                    {/* <td data-label='Status'>
                                                                        {
                                                                            //   <div
                                                                            //      className={`status ${statusClass(
                                                                            //          store
                                                                            //      )}`}
                                                                            //   >

                                                                            //   </div>
                                                                        }
                                                                        {status_name_condition(store)}
                                                                    </td> */}
                                                                    <td data-label='Ações'>
                                                                        <div className="actionsTd">

                                                                            <div></div>
                                                                            {can(
                                                                                'READ',
                                                                                'DIRECT_PURCHASE'
                                                                            ) && (

                                                                                    <i

                                                                                        className="material-symbols-outlined"
                                                                                        title="Parâmetros de venda direta"
                                                                                        data-toggle="modal"
                                                                                        data-target="#company-info-modal"
                                                                                        onClick={() => setStore(store)}
                                                                                    >
                                                                                        info
                                                                                    </i>


                                                                                )}
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                )
                                            }

                                            return (
                                                <React.Fragment key={idx}>
                                                    <tr className='tableSpacer'

                                                    ></tr>
                                                    <tr
                                                    //className='TesteRed'
                                                    >

                                                        <td data-label='Cliente'>
                                                            <div className="headOfficeNameContainer">
                                                                <div>
                                                                    <MdOutlineBadge
                                                                        onClick={() => handleOpenModalCompanyData(customer)}

                                                                    />
                                                                </div>
                                                                {customer.trading_nam
                                                                    ? customer.trading_nam
                                                                    : customer.name}
                                                            </div>
                                                        </td>
                                                        <td data-label='Nome fantasia'>
                                                            {
                                                                //customer.social_reazon
                                                                //    ? customer.social_reazon
                                                                //    : customer.name
                                                                customer.name && customer.head_office.trading_name
                                                                    ? customer.head_office.trading_name.toUpperCase()
                                                                    : customer.social_reazon
                                                            }
                                                        </td>
                                                        <td data-label='CNPJ' className="text-nowrap">
                                                            {customer
                                                                .head_office
                                                                .cnpj
                                                                ? mask_cnpj(
                                                                    customer
                                                                        .head_office
                                                                        .cnpj
                                                                )
                                                                : ''}
                                                        </td>
                                                        <td data-label='UF/Cidade'>
                                                            <div style={{ 'display': 'flex', 'flexDirection': 'column' }}>

                                                                <div style={{ 'marginBottom': 5 }}>
                                                                    {customer
                                                                        .head_office
                                                                        .state.name
                                                                        ? customer.head_office.state.fu
                                                                        : ''}
                                                                </div>
                                                                <div>
                                                                    {customer.head_office.city.name
                                                                        ? customer.head_office.city.name
                                                                        : ''}
                                                                </div>

                                                            </div>

                                                        </td>

                                                        <td data-label='Segmento'>
                                                            {customer.sectors
                                                                ? customer.sectors
                                                                    .map(
                                                                        (s) =>
                                                                            s.name
                                                                    )
                                                                    .join(',')
                                                                : ''}
                                                        </td>
                                                        <td data-label='Contato'>
                                                            <div style={{ 'display': 'flex', 'flexDirection': 'column' }}>
                                                                <div style={{ 'marginBottom': 5 }}>
                                                                    {customer.contact
                                                                        ? customer.contact.toUpperCase()
                                                                        : ''}
                                                                </div>
                                                                {
                                                                    <div>
                                                                        {customer.phone
                                                                            ? <><PhoneWithWhats phone={customer.phone} />
                                                                                {/* <BsTelephoneForward style={{ 'marginRight': 4 }} /> {customer.phone} */}
                                                                            </>
                                                                            : ''}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </td>
                                                        {
                                                            //   <td>
                                                            //
                                                            //       {customer.phone
                                                            //           ? customer.phone
                                                            //           : ''}
                                                            //   </td>
                                                        }
                                                        {/* <td data-label='Status'>
                                                            {
                                                                //  <div
                                                                //      className={`status ${getStatusClass(
                                                                //          customer
                                                                //      )}`}
                                                                //  >
                                                                //  </div>
                                                            }
                                                            {status_name(
                                                                customer
                                                            )}
                                                        </td> */}
                                                        <td data-label='Ações'>
                                                            <div className='actionsTd'>
                                                                {can('READ', 'DIRECT_PURCHASE') && (
                                                                    <i
                                                                        ref={iconRef}
                                                                        className="material-symbols-outlined"
                                                                        data-toggle="modal"
                                                                        title="Parâmetros de venda direta"
                                                                        data-target="#company-info-modal"
                                                                        onClick={() => setStore(customer.head_office)}
                                                                    >info</i>

                                                                )}
                                                                <i
                                                                    className="material-symbols-outlined"
                                                                    title="Vendedores de cotação"
                                                                    data-toggle="modal"
                                                                    data-target="#representatives-modal"
                                                                    onClick={() => setCompany(customer)}
                                                                >group</i>
                                                                {
                                                                    //    <i
                                                                    //        className="material-icons people"
                                                                    //        title="Vendedores de cotação"
                                                                    //        data-toggle="modal"
                                                                    //        data-target="#representatives-modal"
                                                                    //        onClick={() => setCompany(customer)}
                                                                    //    >people</i>
                                                                }




                                                                {can('READ', 'DIRECT_PURCHASE') && customer.head_office && (

                                                                    <MdOutlineAssignmentLate title='Usuários de venda direta' className='editIcon'
                                                                        onClick={() => handleOpenModal(customer, false)}>


                                                                    </MdOutlineAssignmentLate>


                                                                )}
                                                                {
                                                                    //   <i
                                                                    //       onClick={() => handleOpenModal(customer)}
                                                                    //   >{':)'}</i>
                                                                }


                                                            </div>


                                                        </td>
                                                    </tr>
                                                    {stores ? stores : ''}
                                                </React.Fragment>
                                            )
                                        })}
                                </tbody>
                            </table>
                            {clients.meta.total == 1 && showClearButton ?
                                <div style={{
                                    display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 30, padding: 5,
                                    width: '100%'
                                }}  >

                                    <ButtonV3 title='Mostrar todos'
                                        style={{ boxShadow: '1px 1px 2px 2px rgba(0,0,0,0.1)' }}

                                        onClick={() => setQueryParams(initialQueryParams)}
                                    />
                                </div>


                                :
                                <PaginationCustom
                                    total={clients.meta.last_page}
                                    itemsName={'produtos'}
                                    ItemsPorPage="100"
                                    currentPage={clients.meta.current_page}
                                    onPageChange={(page: number) =>
                                        setQueryParams({ ...queryParams, page })}
                                    totalItems={clients.meta.total}
                                />
                            }
                        </div>
                    ) : (
                        <FeedbackError message={Constants.MSG_DATA_NOT_FOUND} />
                    )
                ) : (
                    <Loader type="inline" />
                )}
            </div>

            <BlacklistedModal
                company={store}
                isVisible={show_blacklist_modal}
                onCloseCallback={() => open_blacklisted(false)}
            />

            <MoreInformationModal
                company={store}
                onCloseCallback={() => closeModalInformation()}
            />

            <RepresentativesModal
                company={company}
                onCloseCallback={() => setCompany(null)}
            />

            <RegisterRequestsModal
                isVisible={registerModal}
                onCloseCallback={() => setRegisterModal(false)}
                onRegisterAmount={setRegisterAmount}
                openEditUserInfoModal={handleOpenModal}
            />

            <WeekdayModal
                user={user}
                isVisible={weekdayModal}
                onCloseCallback={() => setWeekdayModal(false)}
            />
            <BaseModal
                data={modalData}
                title={modalTitle}
                isVisible={modalVisible}
                onCloseCallback={() => setModalVisible(false)}
                children={<EditUserInfoForm data={modalData} isSolocitation={isSolicitation} />}
            />
            <BaseModal
                data={null}
                title={`Cliente: ${modalData && modalData.name}`}
                isVisible={companyDataModalVisible}
                onCloseCallback={() => setCompanyDataModalVisible(false)}
                children={<CompanyDetailsModal data={modalData}></CompanyDetailsModal>}
                modalBodyStyle={{ overflow: 'none' }}
            />

        </ProjectContainer >
    )
}

const mapStateToProps = (state: any) => ({
    clients: state.customers.clients,
    states: state.requests.states,
})

const mapDispatchToProps = (dispatch: any) => ({
    load_clients: (params) => dispatch(load_stores(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BuyingCompanies)
