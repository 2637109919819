export default class Constants {

    public static URL_API                               = process.env.REACT_APP_URL_API
    public static URL_API_V2                            = process.env.REACT_APP_URL_API_V2
    public static URL_V3_API                            = process.env.REACT_APP_URL_V3_API
    public static URL_RETURN_ADDRESS                    = `${process.env.REACT_APP_RETURN_ADDRESS}`
    public static ENV                                   = `${process.env.REACT_APP_ENV}`
    public static URL_API_AUTH                          = `${Constants.URL_API}/auth`
    public static URL_API_USER                          = `${Constants.URL_API}/getUser`
    public static URL_API_USER_REGIONS                  = `${Constants.URL_API}/usuario/region`
    public static URL_API_BRANCH_ACTIVITIES             = `${Constants.URL_V3_API}/clients/sectors`
    public static URL_API_GET_LOGGED_PROVIDER           = `${Constants.URL_API}/getfornecedor`
    public static URL_API_UPDATE_LOGGED_PROVIDER        = `${Constants.URL_API}/atualizarfornecedor`
    public static URL_API_UPDATE_PROVIDER_COVER         = `${Constants.URL_V3_API}`
    public static URL_API_ORDER                         = `${Constants.URL_API}/listarpedidos`
    public static URL_API_REQUESTS                      = `${Constants.URL_API}/v2/requests`
    public static URL_V1_API_REQUESTS                   = `/minhasCotacoes`
    public static URL_V1_API_OTHERS_REQUESTS            = `/outrasCotacoes`
    public static URL_API_REQUEST_PRODUCTS_AND_DETAILS  = `/getprodutoscotacaodigitacao`
    public static URL_API_SUBREQUESTS_STORES            = `/v2/requests/{id}/subrequests/stores`
    public static URL_REQUEST_SELLER_DELIVERY_API       = `/v2/requests/{id}/sellers/{seller_id}/delivery`
    public static URL_API_ORDER_CONFIRM                 = `${Constants.URL_API}/confirmarpedido  `
    public static URL_API_ORDER_DETAILS                 = `/getprodutospedido?pedido_id=`
    public static URL_API_SALESMAN                      = `${Constants.URL_API}/getvendedornome`
    public static URL_API_SALESMAN_STORE                = `${Constants.URL_API}/getvendedorloja`
    public static URL_API_PAYMENT_TYPES                 = `/getipopagamento`
    public static URL_API_REPRESENTATIVES               = `${Constants.URL_API}/getvendedores`
    public static URL_API_REPRESENTATIVE_CHANGE_STATUS  = `${Constants.URL_API}/ativarinativarvendedor`
    public static URL_API_DIRECT_SALE_ORDER             = `${Constants.URL_API}/orders`
    public static URL_API_DIRECT_SALE_ORDER_CONFIRM     = `/orders/confirm`
    public static URL_API_DIRECT_SALE_ORDER_CANCEL      = `/orders/cancel`
    public static URL_API_CUSTOMER                      = `${Constants.URL_API}/v2/clients`
    public static URL_API_MANAGER                       = `${Constants.URL_API}/v2/reports/requests`
    public static URL_API_ASSOCIATED_CUSTOMER           = `${Constants.URL_API}/getclientesnaoassociadosvendedor`
    public static URL_API_ASSOCIATE_CUSTOMER            = `${Constants.URL_API}/associarvendedorcliente`
    public static URL_API_BUING_COMPANIES               = `${Constants.URL_API}/getrempresasvendedores`
    public static URL_API_PROVIDER                      = `${Constants.URL_API}/v2/providers`
    public static URL_API_PROVIDER_LEVELS_ALL           = `${Constants.URL_API}/providers/products/levels/descricao/all`
    public static URL_V2_API_CLIENT_DELIVERIES          = `/v2/clients/store/{store_id}/delivery`
    public static URL_V2_API_CLIENTS                    = `/v2/providers/clients`

    public static URL_API_PROVIDER_SALES_REPORT         = `${Constants.URL_V3_API}/providers/reports/produto/rel`
    public static URL_API_PROVIDER_GROUPS               = `${Constants.URL_V3_API}/admin`
    public static URL_API_PROVIDER_CATEGORIES           = `${Constants.URL_V3_API}/providers/categories/provider`
    public static URL_API_PROVIDER__CREATE_CATEGORIE    = `${Constants.URL_V3_API}/providers/categories`
    public static URL_API_PROVIDER__CREATE_SUBCATEGORIE = `${Constants.URL_V3_API}/providers/categories/sub`

    public static URL_SAVE_DELIVERY                     = `/entregaoutrascotacoes`
    public static URL_SAVE_OFFER                        = `/digitarcotacaopreco`
    public static URL_SAVE_OFFER_QUANTITY               = `/digitarcotacaoquantidade`
    public static URL_MARK_REQUEST_NO_OFFERS            = `/naotrabalharcotacao`

    public static ROUTE_SIGNIN                    = '/login'
    public static ROUTE_DIRECT_SALE_ORDER         = '/pedidosdireto'
    public static ROUTE_SALES_REPORT              = '/relatorios'
    public static ROUTE_NOT_WINNER_REPORT         = '/naovencedores'
    public static ROUTE_WINNER_REPORT             = '/vencedores'
    public static ROUTE_GROUP_PRODUCTS            = '/produtos/groups'
    public static ROUTE_PRODUCTS                  = '/produtos'
    public static ROUTE_PRODUCTS_ADD              = '/produtos/add'
    public static ROUTE_PRODUCTS_EDIT             = '/produtos/edit/'
    public static ROUTE_PRODUCTS_EDIT_PRICE_LEVEL = '/produtos/edit/prices/'
    public static ROUTE_PROVIDER_EDIT             = '/provider/edit/'
    public static ROUTE_CLIENTS                   = '/clientes'
    public static ROUTE_BUYING_COMPANIES          = '/buying-companies'
    public static ROUTE_ORDERS                    = '/orders'
    public static ROUTE_REPRESENTATIVES           = '/representatives'
    public static ROUTE_REPRESENTATIVES_ADD       = '/representatives/add'
    public static ROUTE_CAMPAINS_ADD              = '/campaign/add'
    public static ROUTE_CAMPAIGNS_EDIT             = '/campaign/edit/'
    public static ROUTE_REPRESENTATIVES_EDIT      = '/representatives/edit'
    public static ROUTE_REPRESENTATIVES_CLIENTS   = '/representative/:id/clients'
    public static ROUTE_REQUESTS                  = '/cotacoes'
    public static ROUTE_REQUEST_DETAIL            = '/cotacoes/:id/vendedor/:seller_id'
    public static ROUTE_MANAGER_ANALITIC          = '/cotacoes/:id/vendedor/:seller_id/gerencial'
    public static ROUTE_DASHBOARD                 = '/dashboard'
    public static ROUTE_CAMPAIGNS                 = '/campaigns'
    public static ROUTE_MONITORING                 = '/monitor'
    public static ROUTE_REQUEST_ACCESS            = '/cadastro'
    public static ROUTE_REQUEST_ACCESS_PROVIDER   = '/requisicao/fornecedor'
    public static ROUTE_ABRASEL_BH                = '/ba/cadastro'
    public static ROUTE_SINDIBARES_GO             = '/go/cadastro'
    public static ROUTE_VRSYSTEM                  = '/cadastro/vr'

    public static STATUS_ACTIVE                   = 'ACTIVE'
    public static STATUS_INACTIVE                 = 'INACTIVE'
    public static STATUS_PENDING                  = 'PENDENTE'

    public static TAB_INFO_MODAL_PARAMETER        = 'Parâmetros da venda direta'

    public static HTTP_CODE_SUCCESS               = '200'

    public static BODY_LOCK_CLASSNAME             = 'lock'

    public static STORAGE_TOKEN_KEY               = 'token'

    public static MSG_DATA_NOT_FOUND              = 'Nenhum resultado encontrado.'
    public static MSG_DIRECT_SALE_ORDER_ADVISE    = "Não deixe de confirmar seus pedidos recebidos, esta operação é fundamental."
    public static MSG_ORDER_ADVISE                = 'Não deixe de confirmar seus pedidos recebidos, esta operação é fundamental.'
    public static MSG_REQUESTS_ADVISE             = 'Visualize as cotações disponíveis para você'
    public static MSG_REQUIRED_FIELD              = 'Campo obrigatório'
    public static MSG_PATTERN_FIELD               = 'Campo inválido'
    public static MSG_AUTH_FAIL                   = 'Falha ao efetuar o login'
    public static MSG_ORDER_LOAD_FAIL             = 'Não foi possível acessar seu pedido'
    public static MSG_ORDER_CONFIRM_FAIL          = 'Não foi possível confirmar seu pedido'
    public static MSG_REQUEST_FAIL                = 'Falha ao realizar solicitação'
    public static MSG_ORDER_CONFIRM_SUCCESS       = 'Pedido confirmado com sucesso!'
    public static MSG_ORDER_CONFIRM_QUESTION      = 'Tem certeza que quer confirmar o pedido?'
    public static MSG_LENGTH_FIELD                = 'Tamanho do campo inválido'
    public static MSG_INVALID_FIELD               = 'Formato do campo inválido'
    public static CURRENCY_SIGNAL                 = 'R$ '

    public static MSG_PRODUCT_CONFIRM_REMOVAL     = 'Tem certeza que quer remover o produto?'
    public static MSG_PRODUCT_SUCCESS_REMOVAL     = 'Produto removido com sucesso!'
    public static MSG_PRODUCT_ERROR_REMOVAL       = 'Ops! Houve um erro ao remover o produto'

    public static MSG_PROVIDER_SUCCESS_EDIT       = 'Dados do fornecedor alterado com sucesso!'
    public static MSG_PROVIDER_ERROR_EDIT         = 'Erro ao salvar os dados do fornecedor!'

    public  static MSG_CAMPAIGN_DELETE_SUCESS     = 'Campanha deletada com sucesso!'
    public  static MSG_CAMPAIGN_DELETE_ERROR      = 'Falha ao deletar a campanha!'

    public static DEFAULT_TOGGLE_BUTTON_COLLORS = {
        activeThumb: { base: 'white' },
        inactiveThumb: { base: 'white' },
        active: { base: '#ff7110', hover: '#ff7110c9' },
        inactive: { base: '#e2e2e2', hover: '#d9d9d9' }
    }

}
