import Constants from '../constants'
import { DirectSaleProduct } from '../interfaces/directSaleProduct'
import { DirectSaleProductLevel } from '../interfaces/directSaleProductLevel'
import HttpClient from './HttpClient'

export default class DirectSaleProductService {
    static DEFAULT_REMOVED = 0
    static DEFAULT_STATUS = 1
    static DEFAULT_CATEGORY = null
    static DEFAULT_SUBCATEGORY = 22

    static parseProductLevel = (
        productLevelFromApi: any
    ): DirectSaleProductLevel => ({
        code: productLevelFromApi.Cod_pro,
        level: productLevelFromApi.Nivel,
        price: productLevelFromApi.Preco,
        provider: productLevelFromApi.Fornecedor,
        desc: productLevelFromApi.descricao,
    })

    static parse = (productFromApi: any): DirectSaleProduct => ({
        id: productFromApi.pro_id,
        internalCode: productFromApi.pro_codInterno,
        ean: productFromApi.pro_ean,
        description: productFromApi.pro_descricao,
        packing: productFromApi.pro_embalagem,
        packingQuantity: productFromApi.pro_embalagemQtd,
        dailyLimit: productFromApi.pro_limite_diario,
        stock: productFromApi.pro_estoque,
        additionalInformations: productFromApi.pro_informacoes_adicionais,
        picture: productFromApi.pro_imagem,
        pro_qtd_min: productFromApi.pro_qtd_min,
        pro_caixa: productFromApi.pro_caixa,
        status: productFromApi.pro_status === '1',
        prod_terceiro: productFromApi.prod_terceiro,
        produto_fracionado: productFromApi.produto_fracionado,
        storage_control: productFromApi.storage_control,
        subcategoria: productFromApi.pro_sub_cat_id,
        categoria: productFromApi.pro_cat_for_id
    })

    // static parseV2 = (productFromApi: any): Product => ({
    //   id: productFromApi.produto_id,
    //   internalCode: productFromApi.cod_interno,
    //   ean: productFromApi.ean,
    //   description: productFromApi.produto,
    //   packing: productFromApi.embalagem,
    //   packingQuantity: productFromApi.quantidade,
    //   dailyLimit: '',
    //   stock: productFromApi.estoque,
    //   additionalInformations: productFromApi.informacoes_adicionais,
    //   picture: productFromApi.imagem,
    // });

    static getProductLevels = (
        idProduct?: string
    ): Promise<DirectSaleProductLevel[]> =>
        HttpClient.get(`/providers/products/levels/${idProduct}`).then(
            (response) => {
                const levels: DirectSaleProductLevel[] = []
                response.forEach((productLevel: any) =>
                    levels.push(
                        DirectSaleProductService.parseProductLevel(productLevel)
                    )
                )
                return levels
            }
        )

    static getAll = (providerId: string, filters: any = {}): Promise<any[]> =>
        HttpClient.get(
            `${Constants.URL_API_PROVIDER
            }/${providerId}/products?product_name_filter=${filters.product_name || ''
            }&page=${filters.page}&active_only=0&group_id=${filters.group || ''
            }&pro_ean=${filters.pro_ean || ''}&cat_id=${filters.category || ''}&sub_cat_id=${filters.subcategory || ''}`
        ).then((response) => {
            const products: DirectSaleProduct[] = []
            response.produtos.data.forEach((product: any) =>
                products.push(DirectSaleProductService.parse(product))
            )
            const data = { ...response.produtos, produtos: products }

            return data
        })

    static getById = (
        providerId: string,
        productId: any
    ): Promise<DirectSaleProduct> =>
        HttpClient.get(
            `/v2/providers/${providerId}/products/${productId}`
        ).then((response) => DirectSaleProductService.parse(response.produtos))

    static add = (
        product: DirectSaleProduct,
        levels: number[],
        providerId: string
    ): Promise<any> =>
        HttpClient.post(`/v2/providers/${providerId}/products`, {
            levels,
            descricao: product.description,
            embalagem: product.packing,
            ean: product.ean,
            cod_interno: product.internalCode,
            qtd_embalagem: product.packingQuantity,
            pro_caixa: product.pro_caixa,
            limite_diario: product.dailyLimit,
            estoque: product.stock,
            picture: product.picture,
            pro_qtd_min: product.pro_qtd_min,
            prod_terceiro: product.prod_terceiro,
            storage_control: product.storage_control,
            produto_fracionado: product.produto_fracionado,
            categoria: product.categoria,
            subcategoria: product.subcategoria,
            classificacao: product.classificacao,
            // subcategoria: DirectSaleProductService.DEFAULT_SUBCATEGORY,
            pro_informacoes_adicionais: product.additionalInformations,
            status: DirectSaleProductService.DEFAULT_STATUS,
        })

    static edit = (
        product: DirectSaleProduct,
        levels: number[],
        providerId: string
    ): Promise<any> =>
        HttpClient.put(
            `${Constants.URL_API_PROVIDER}/${providerId}/products/${product.id}`,
            {
                levels,
                descricao: product.description,
                embalagem: product.packing,
                ean: product.ean,
                cod_interno: product.internalCode,
                qtd_embalagem: product.packingQuantity,
                pro_caixa: product.pro_caixa,
                limite_diario: product.dailyLimit,
                estoque: product.stock,
                picture: product.picture,
                pro_qtd_min: product.pro_qtd_min,
                storage_control: product.storage_control,
                categoria: product.categoria,
                // prodSubcategoria: product.subcategoria,
                subcategoria: product.subcategoria,
                classificacao: product.classificacao,
                // subcategoria: DirectSaleProductService.DEFAULT_SUBCATEGORY,
                pro_informacoes_adicionais: product.additionalInformations,
                status: DirectSaleProductService.DEFAULT_STATUS,
                excluido: DirectSaleProductService.DEFAULT_REMOVED,
            }
        )



    static updateLevels = (
        product: DirectSaleProduct,
        levels: number[],
        providerId: string
    ): Promise<any> =>
        HttpClient.put(
            `/v2/providers/${providerId}/products/${product.id}/levels`,
            {
                levels,
            }
        )
}
